











import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class ViewTutorial extends Vue {
  tutoSrc = ''
  created() {
    this.$store.dispatch('getUserTuto').then(json => {
      if (json.success) {
        this.tutoSrc = json.data
      }
    })
  }
}
